<template>
  <div>
    <ReportsNew v-if="useNew" />
    <ReportsOld v-else />
  </div>
</template>

<script>
import ReportsNew from './reports/ReportsNew.vue';
import ReportsOld from './reports/ReportsOld.vue';

export default {
  components: {
    ReportsNew,
    ReportsOld,
  },
  computed: {
    useNew() {
      const features = this.$store.getters.features;
      return features['new-reports-ui'] === 'true';
    },
  },
};
</script>
