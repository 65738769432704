<template>
  <div>
    <div class="tw-border-b tw-border-gray-300">
      <div class="tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-5">
        <h3 class="tw-text-2xl tw-capitalize tw-font-medium tw-inline-flex tw-items-center">
          {{ $t('_reports') }}
          <tooltip-select tip="_reports" class="tw-ml-2"></tooltip-select>
        </h3>
      </div>
    </div>
    <div class="tw-py-4 tw-px-3">
      <v-layout row wrap>
        <v-flex xs12 :class="{ 'pr-3': $vuetify.breakpoint.mdAndUp, 'pb-4': true }">
          <v-list two-line>
            <template v-for="(item, index) in items">
              <div v-if="item.enabled === undefined || item.enabled" :key="index">
                <v-subheader v-if="item.header" :key="item.header">
                  {{ item.header }}
                </v-subheader>

                <v-divider v-else-if="item.divider" :key="index"></v-divider>

                <v-list-tile v-else :key="item.title" avatar router :to="item.to">
                  <v-list-tile-content>
                    <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                    <v-list-tile-sub-title>{{ item.subtitle }}</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </div>
            </template>
          </v-list>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const items = [
      {
        title: this.$tc('_balanceReport', 2),
        subtitle: 'Report on the balance of all accounts as of the end of a particular day.',
        to: '/reports/balanceReport',
      },
      {
        title: 'Balance Report (Expanded)',
        subtitle: 'Expanded Report on the balance of all accounts as of the end of a particular day.',
        to: '/reports/expandedBalanceReport',
      },
      {
        title: this.$t('_exportTransactionReport'),
        subtitle: 'Export all transactions in CSV format.',
        to: '/reports/exports',
      },
      {
        title: this.$tc('_journalReport', 2),
        subtitle: 'Export of expected expanded journal entries in target accounting system.',
        to: '/reports/journalReport',
      },
      {
        title: this.$tc('_journalReportExpanded', 2),
        subtitle: 'Export of expected expanded journal entries in target accounting system.',
        to: '/reports/expandedJournalReport',
      },
      {
        title: this.$tc('_rolledUpJournalReport', 2),
        subtitle: 'Export of expected journal entries in target accounting system grouped by contacts and categories.',
        to: '/reports/rolledUpJournalReport',
      },
      {
        title: this.$tc('_ledgerReport'),
        subtitle: 'Ledger view of transactions with running balance.',
        to: '/reports/ledgerReport',
      },
      {
        title: this.$tc('_balanceCheckReport'),
        subtitle: 'Run balance compares from api data to Bitwave data.',
        to: '/reports/balanceCheckReport',
        // enabled: this.checkFeatureFlag('balance-check-enabled'), // How to gate from users in future if needed
      },
    ];
    return {
      items,
    };
  },
};
</script>
